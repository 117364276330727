import flatpickr from "flatpickr";

const initFlatpickr = () => {
  const djBookingForm = document.querySelector('#dj-booking-form');
  if (djBookingForm) {
    const data = document.querySelector('#dj-booking-form').dataset.reserved
    const reserved_dates = JSON.parse(data)
    flatpickr(".datepicker", {
      minDate: "today",
      locale: {
        "firstDayOfWeek": 1
      },
      disable: reserved_dates,
      dateFormat: "Y-m-d"
    });
  }


  flatpickr(".doors-open", {
    noCalendar: true,
    enableTime: true,
    time_24hr: true,
    minTime: "12:00",
    maxTime: "23:59"
  })
  flatpickr(".venue-curfew", {
    noCalendar: true,
    enableTime: true,
    time_24hr: true,
    minTime: "00:00",
    maxTime: "11:00"
  })
  flatpickr(".time-on-stage", {
    noCalendar: true,
    enableTime: true,
    time_24hr: true,
  })
}

export { initFlatpickr };